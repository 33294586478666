import Vue from 'vue'

// Replace underscores with spaces and capitalize words
Vue.filter('humanize', value => {
  if (value) {
    let i; const
      frags = value.split('_')
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
    }
    return frags.join(' ')
  }
})

// Format amount and replace comma with dot
Vue.filter('formatAmount', value => {
  if (value) {
    return Number(value).toLocaleString().replace(/,/g, '.')
  }
  return 0
})
