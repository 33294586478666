import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: process.env.API_BASE_URL,
  headers: {
    Accept: 'application/json',
  },
})

Vue.prototype.$http = axiosIns

axiosIns.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('userData')
    localStorage.removeItem('permissions')
    window.location.href = `${window.location.origin}/login`
  }

  if (error.response.status === 403) {
    window.location.href = `${window.location.origin}/error-403`
  }

  return Promise.reject(error)
})

export default axiosIns
