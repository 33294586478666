import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDateRegister', value => {
  if (value) {
    return moment(String(value)).format('HH:mm DD MMMM YYYY')
  }
})

Vue.filter('formatBirthDate', value => {
  if (value) {
    return moment(String(value)).format('DD MMMM YYYY')
  }
})
