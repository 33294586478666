import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [{
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      guest: true,
      layout: 'full',
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      auth: true,
      pageTitle: 'Home',
      breadcrumb: [{
        text: 'Home',
        active: true,
      }],
    },
  },
  {
    path: '/users',
    name: 'users.index',
    component: () => import('@/views/user/UserManagement.vue'),
    meta: {
      auth: true,
      pageTitle: 'List Users',
      breadcrumb: [{
        text: 'User',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
    },
  },
  {
    path: '/user-detail/:id',
    name: 'users.show',
    component: () => import('@/views/user/DetailUser.vue'),
    meta: {
      auth: true,
      pageTitle: 'Detail User',
      breadcrumb: [{
        text: 'User',
        active: true,
      },
      {
        text: 'Detail',
        active: true,
      },
      ],
      navActiveLink: 'users.index',
    },
  },
  {
    path: '/role',
    name: 'role.index',
    component: () => import('@/views/setting/RoleManagement.vue'),
    meta: {
      auth: true,
      pageTitle: 'Role Management',
      breadcrumb: [{
        text: 'Role',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
    },
  },
  {
    path: '/admin',
    name: 'admin.index',
    component: () => import('@/views/admin/AdminManagement.vue'),
    meta: {
      auth: true,
      pageTitle: 'List Admin',
      breadcrumb: [{
        text: 'Admin',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
    },
  },
  {
    path: '/setting',
    name: 'setting.index',
    component: () => import('@/views/setting/SettingPage.vue'),
    meta: {
      auth: true,
      pageTitle: 'Settings',
      breadcrumb: [{
        text: 'Setting',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/setting/Profile.vue'),
    meta: {
      auth: true,
      pageTitle: 'Profile',
      breadcrumb: [{
        text: 'Profile',
        active: true,
      }],
    },
  },
  {
    path: '/transaction/pital-pay',
    name: 'pay.index',
    component: () => import('@/views/wallet/TransactionPitalPay.vue'),
    meta: {
      auth: true,
      pageTitle: 'List Transaction',
      breadcrumb: [{
        text: 'Pital Pay',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
    },
  },
  {
    path: '/transaction/pital-coin',
    name: 'coin.index',
    component: () => import('@/views/wallet/TransactionPitalCoin.vue'),
    meta: {
      auth: true,
      pageTitle: 'List Transaction',
      breadcrumb: [{
        text: 'Pital Coin',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
    },
  },
  {
    path: '/withdraw',
    name: 'withdraw.index',
    component: () => import('@/views/wallet/TransactionWithdraw.vue'),
    meta: {
      auth: true,
      pageTitle: 'List Withdraw',
      breadcrumb: [{
        text: 'Withdraw',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
    },
  },
  {
    path: '/withdraw-detail/:id',
    name: 'withdraw.show',
    component: () => import('@/views/wallet/DetailWithdraw.vue'),
    meta: {
      auth: true,
      pageTitle: 'Detail Withdraw',
      breadcrumb: [{
        text: 'Witdraw',
        active: true,
      },
      {
        text: 'Detail',
        active: true,
      },
      ],
      navActiveLink: 'withdraw.index',
    },
  },
  {
    path: '/topup',
    name: 'topup.index',
    component: () => import('@/views/wallet/TransactionTopup.vue'),
    meta: {
      auth: true,
      pageTitle: 'List Topup',
      breadcrumb: [{
        text: 'Topup',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
    },
  },
  {
    path: '/topup-detail/:id',
    name: 'topup.show',
    component: () => import('@/views/wallet/DetailTopup.vue'),
    meta: {
      auth: true,
      pageTitle: 'Detail Topup',
      breadcrumb: [{
        text: 'Topup',
        active: true,
      },
      {
        text: 'Detail',
        active: true,
      },
      ],
      navActiveLink: 'topup.index',
    },
  },
  {
    path: '/list-referral',
    name: 'referral.index',
    component: () => import('@/views/referral/ReferralList.vue'),
    meta: {
      auth: true,
      pageTitle: 'List Referral',
      breadcrumb: [{
        text: 'Referral',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'referral.index',
    },
  },
  {
    path: '/talenta',
    name: 'talenta.index',
    component: () => import('@/views/talenta/TalentaManagement.vue'),
    meta: {
      auth: true,
      pageTitle: 'List Talenta',
      breadcrumb: [{
        text: 'Talenta',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'talenta.index',
    },
  },
  {
    path: '/talenta-detail/:id',
    name: 'talenta.show',
    component: () => import('@/views/talenta/DetailTalent.vue'),
    meta: {
      auth: true,
      pageTitle: 'Detail Talenta',
      breadcrumb: [{
        text: 'Talenta',
        active: true,
      },
      {
        text: 'Detail',
        active: true,
      },
      ],
      navActiveLink: 'talenta.index',
    },
  },
  {
    path: '/talenta/dompet-hasil-usaha',
    name: 'talenta.business.index',
    component: () => import('@/views/talenta/wallet/TransactionDompetHasilUsaha.vue'),
    meta: {
      auth: true,
      pageTitle: 'List Dompet Hasil Usaha',
      breadcrumb: [{
        text: 'Dompet Hasil Usaha',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'talenta.business.index',
    },
  },
  {
    path: '/talenta/dompet-hasil-usaha/withdraw',
    name: 'talenta.withdraw-dompet.index',
    component: () => import('@/views/talenta/wallet/business/TransactionWithdraw.vue'),
    meta: {
      auth: true,
      pageTitle: 'List Withdraw',
      breadcrumb: [{
        text: 'Withdraw',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'talenta.withdraw-dompet.index',
    },
  },
  {
    path: '/talenta/dompet-hasil-usaha/withdraw-detail/:id',
    name: 'talenta.withdrwaw-dompet.show',
    component: () => import('@/views/talenta/wallet/business/DetailWithdraw.vue'),
    meta: {
      auth: true,
      pageTitle: 'Detail Withdraw',
      breadcrumb: [{
        text: 'Withdraw',
        active: true,
      },
      {
        text: 'Detail',
        active: true,
      },
      ],
      navActiveLink: 'talenta.withdraw-dompet.index',
    },
  },
  {
    path: '/talenta/pital-poin/',
    name: 'talenta.poin.index',
    component: () => import('@/views/talenta/wallet/TransactionPitalPoin.vue'),
    meta: {
      auth: true,
      pageTitle: 'List Pital Poin',
      breadcrumb: [{
        text: 'Pital Poin',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'talenta.poin.index',
    },
  },
  {
    path: '/talenta/pital-poin/topup',
    name: 'talenta.topup-poin.index',
    component: () => import('@/views/talenta/wallet/topup/poin/TransactionTopup.vue'),
    meta: {
      auth: true,
      pageTitle: 'List Topup',
      breadcrumb: [{
        text: 'Topup',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'talenta.topup-poin.index',
    },
  },
  {
    path: '/talenta/pital-poin/topup-detail/:id',
    name: 'talenta.topup-poin.show',
    component: () => import('@/views/talenta/wallet/topup/poin/DetailTopup.vue'),
    meta: {
      auth: true,
      pageTitle: 'Detail Topup',
      breadcrumb: [{
        text: 'Topup',
        active: true,
      },
      {
        text: 'Detail',
        active: true,
      },
      ],
      navActiveLink: 'talenta.topup-poin.index',
    },
  },
  {
    path: '/talenta/pital-ads/',
    name: 'talenta.ads.index',
    component: () => import('@/views/talenta/wallet/TransactionPitalAds.vue'),
    meta: {
      auth: true,
      pageTitle: 'List Pital Ads',
      breadcrumb: [{
        text: 'Pital Ads',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'talenta.ads.index',
    },
  },
  {
    path: '/talenta/pital-ads/topup',
    name: 'talenta.topup-ads.index',
    component: () => import('@/views/talenta/wallet/topup/ads/TransactionTopup.vue'),
    meta: {
      auth: true,
      pageTitle: 'List Topup',
      breadcrumb: [{
        text: 'Topup',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'talenta.topup-ads.index',
    },
  },
  {
    path: '/talenta/pital-ads/topup-detail/:id',
    name: 'talenta.topup-ads.show',
    component: () => import('@/views/talenta/wallet/topup/ads/DetailTopup.vue'),
    meta: {
      auth: true,
      pageTitle: 'Detail Topup',
      breadcrumb: [{
        text: 'Topup',
        active: true,
      },
      {
        text: 'Detail',
        active: true,
      },
      ],
      navActiveLink: 'talenta.topup-ads.index',
    },
  },
  {
    path: '/category',
    name: 'category.index',
    component: () => import('@/views/marketplace/category/CategoryManagement.vue'),
    meta: {
      auth: true,
      pageTitle: 'Category',
      breadcrumb: [{
        text: 'Category',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'category.index',
    },
  },
  {
    path: '/jasa',
    name: 'jasa.index',
    component: () => import('@/views/marketplace/jasa/ListJasa.vue'),
    meta: {
      auth: true,
      pageTitle: 'Jasa',
      breadcrumb: [{
        text: 'Jasa',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'jasa.index',
    },
  },
  {
    path: '/jasa-detail/:id',
    name: 'jasa.show',
    component: () => import('@/views/marketplace/jasa/DetailJasa.vue'),
    meta: {
      auth: true,
      pageTitle: 'Jasa',
      breadcrumb: [{
        text: 'Jasa',
        active: true,
      },
      {
        text: 'Detail',
        active: true,
      },
      ],
      navActiveLink: 'jasa.index',
    },
  },
  {
    path: '/lowongan',
    name: 'vacancy.index',
    component: () => import('@/views/marketplace/vacancy/ListVacancy.vue'),
    meta: {
      auth: true,
      pageTitle: 'Lowongan',
      breadcrumb: [{
        text: 'Lowongan',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'vacancy.index',
    },
  },
  {
    path: '/lowongan-detail/:id',
    name: 'vacancy.show',
    component: () => import('@/views/marketplace/vacancy/DetailVacancy.vue'),
    meta: {
      auth: true,
      pageTitle: 'Lowongan',
      breadcrumb: [{
        text: 'Lowongan',
        active: true,
      },
      {
        text: 'Detail',
        active: true,
      },
      ],
      navActiveLink: 'vacancy.index',
    },
  },
  {
    path: '/kupon',
    name: 'coupon.index',
    component: () => import('@/views/coupon/CouponManagement.vue'),
    meta: {
      auth: true,
      pageTitle: 'Kupon',
      breadcrumb: [{
        text: 'Kupon',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'coupon.index',
    },
  },
  {
    path: '/bank',
    name: 'bank.index',
    component: () => import('@/views/bank/BankController.vue'),
    meta: {
      auth: true,
      pageTitle: 'Bank',
      breadcrumb: [{
        text: 'Bank',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'bank.index',
    },
  },
  {
    path: '/list-tiket-undian',
    name: 'ticket.index',
    component: () => import('@/views/ticket/ListTicket.vue'),
    meta: {
      auth: true,
      pageTitle: 'Tiket Undian',
      breadcrumb: [{
        text: 'Tiket Undian',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'ticket.index',
    },
  },
  {
    path: '/list-complaint',
    name: 'complaint.index',
    component: () => import('@/views/complaint/ListComplaint.vue'),
    meta: {
      auth: true,
      pageTitle: 'Keluhan',
      breadcrumb: [{
        text: 'Keluhan',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'complaint.index',
    },
  },
  {
    path: '/detail-complaint/:id',
    name: 'complaint.show',
    component: () => import('@/views/complaint/DetailComplaint.vue'),
    meta: {
      auth: true,
      pageTitle: 'Keluhan',
      breadcrumb: [{
        text: 'Keluhan',
        active: true,
      },
      {
        text: 'Detail',
        active: true,
      },
      ],
      navActiveLink: 'complaint.index',
    },
  },
  {
    path: '/list-pembayaran',
    name: 'payment.index',
    component: () => import('@/views/payment/ListPayment.vue'),
    meta: {
      auth: true,
      pageTitle: 'Pembayaran',
      breadcrumb: [{
        text: 'Pembayaran',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'payment.index',
    },
  },
  {
    path: '/list-order',
    name: 'order.index',
    component: () => import('@/views/order/ListOrder.vue'),
    meta: {
      auth: true,
      pageTitle: 'Order',
      breadcrumb: [{
        text: 'Order',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'order.index',
    },
  },
  {
    path: '/detail-order/:id',
    name: 'order.show',
    component: () => import('@/views/order/DetailOrder.vue'),
    meta: {
      auth: true,
      pageTitle: 'Order',
      breadcrumb: [{
        text: 'Order',
        active: true,
      },
      {
        text: 'Detail',
        active: true,
      },
      ],
      navActiveLink: 'order.index',
    },
  },
  {
    path: '/banner-slider',
    name: 'bannerslider.index',
    component: () => import('@/views/banner-slider/ListBannerSlider.vue'),
    meta: {
      auth: true,
      pageTitle: 'Banner/Slider',
      breadcrumb: [{
        text: 'Banner/Slider',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'bannerslider.index',
    },
  },
  {
    path: '/static-image',
    name: 'staticImage.index',
    component: () => import('@/views/static-image/ListStaticImage.vue'),
    meta: {
      auth: true,
      pageTitle: 'Static Image',
      breadcrumb: [{
        text: 'Static Image',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'staticImage.index',
    },
  },
  {
    path: '/static-page',
    name: 'staticPage.index',
    component: () => import('@/views/static-page/ListStaticPage.vue'),
    meta: {
      auth: true,
      pageTitle: 'Static Page',
      breadcrumb: [{
        text: 'Static Page',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'staticPage.index',
    },
  },
  {
    path: '/paket-talenta-jagoan',
    name: 'packageTalentaJagoan.index',
    component: () => import('@/views/talenta-jagoan/package-talenta-jagoan/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Paket Talenta Jagoan',
      breadcrumb: [{
        text: 'Paket Talenta Jagoan',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'packageTalentaJagoan.index',
    },
  },
  {
    path: '/order-paket-talenta-jagoan',
    name: 'orderPackageTalentaJagoan.index',
    component: () => import('@/views/talenta-jagoan/order-talenta-jagoan/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Order Paket Talenta Jagoan',
      breadcrumb: [{
        text: 'Order Paket Talenta Jagoan',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'orderPackageTalentaJagoan.index',
    },
  },
  {
    path: '/campaign',
    name: 'campaign.index',
    component: () => import('@/views/campaign/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Campaign',
      breadcrumb: [{
        text: 'Campaign',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'campaign.index',
    },
  },
  {
    path: '/campaign/:id',
    name: 'campaign.show',
    component: () => import('@/views/campaign/show.vue'),
    meta: {
      auth: true,
      pageTitle: 'Campaign',
      breadcrumb: [{
        text: 'Campaign',
        active: true,
      },
      {
        text: 'Detail',
        active: true,
      },
      ],
      navActiveLink: 'campaign.index',
    },
  },
  {
    path: '/misi',
    name: 'missions.index',
    component: () => import('@/views/missions/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Missions',
      breadcrumb: [{
        text: 'Missions',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'missions.index',
    },
  },
  {
    path: '/misi/:id',
    name: 'missions.show',
    component: () => import('@/views/missions/show.vue'),
    meta: {
      auth: true,
      pageTitle: 'Missions',
      breadcrumb: [{
        text: 'Missions',
        active: true,
      },
      {
        text: 'Detail',
        active: true,
      },
      ],
      navActiveLink: 'missions.index',
    },
  },
  {
    path: '/gift-voucher-design',
    name: 'gift-voucher-design.index',
    component: () => import('@/views/gift-voucher/gift-voucher-design/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Gift Voucher Design',
      breadcrumb: [{
        text: 'Gift Voucher Design',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'gift-voucher-design.index',
    },
  },
  {
    path: '/history-order',
    name: 'history-order.index',
    component: () => import('@/views/gift-voucher/history-order/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'History Order',
      breadcrumb: [{
        text: 'History Order',
        active: true,
      },
      {
        text: 'List',
        active: true,
      },
      ],
      navActiveLink: 'history-order.index',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  ],
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()

  // Redirect if not authenticated
  if (to.matched.some(record => record.meta.auth)) {
    if (!isLoggedIn) {
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }

  // Redirect if authenticated
  if (to.matched.some(record => record.meta.guest) && isLoggedIn) {
    next(getHomeRouteForLoggedInUser())
  }
})

export default router
